<template>
  <Container
    tag="section"
    :space-y="index > 0"
    :class="{ 'pb-16 sm:pb-28': index === 0 }"
  >
    <!-- INTRO-TEXT -->
    <div
      v-if="data.text"
      class="grid max-w-container gap-10 px-container sm:px-container-lg md:grid-cols-2 md:gap-16 lg:gap-24"
    >
      <div
        :style="{ '--wysiwyg-text-color': '#6479AB' }"
        class="wysiwyg-custom-text wysiwyg-a-underline mb-8 max-w-text md:col-start-2"
        v-html="data.text"
      />
    </div>
    <!-- SLIDER -->
    <div
      :data-currentSlide="activeIndex"
      class="playstate-handler relative flex w-full flex-col overflow-hidden [overflow-anchor:none]"
      :class="
        data.height.value === 'large'
          ? 'h-[95svh]'
          : 'h-[65svh] lg:h-[50svh] xl:h-[60svh] 2xl:h-[69svh] 3xl:h-[75svh]'
      "
    >
      <!-- IMAGES -->
      <div
        v-for="(slide, index) in data.slides"
        :key="slide.id"
        class="group"
        :data-running="index === activeIndex"
      >
        <AppImage
          :src="slide.image || (slide.entry as any).replicator_heros[0]?.image"
          class="animate-image-scaling absolute size-full object-cover"
          loading="lazy"
          :class="
            index === activeIndex
              ? 'opacity-100 duration-1000 ease-in-out'
              : 'opacity-0 delay-200 duration-500 ease-out'
          "
        />
        <!-- GRADIENT -->
        <GradientOverlay
          :gradient-style="gradientStyle(slide)"
          class="absolute inset-0 transition-opacity"
          :class="[
            index === activeIndex
              ? 'opacity-100 delay-100 duration-500 ease-out'
              : 'opacity-0 delay-300 duration-300 ease-out',
          ]"
        />
      </div>
      <!-- CONTENT -->
      <Container
        :style="[
          '--slide-content-color:' +
            (gradientStyle(data.slides[activeIndex]) === 'beige'
              ? '#303D5C'
              : '#E9E7E3'),
          '--slide-text-color:' +
            (gradientStyle(data.slides[activeIndex]) === 'beige'
              ? '#6479AB'
              : '#FFFFFF'),
        ]"
        tag="div"
        class="relative grid auto-cols-fr overflow-hidden px-container transition-colors delay-150 duration-200 ease-in max-lg:mt-auto max-lg:w-full max-lg:gap-y-12 sm:px-container-md lg:grow lg:grid-flow-col lg:gap-x-6"
        :class="data.height.value === 'large' ? 'pb-10 md:pb-18' : 'pb-10'"
      >
        <div
          v-for="(slide, index) in data.slides"
          :key="slide.id"
          :class="[
            'group relative flex flex-col transition-opacity ease-in lg:justify-end',
            'data-[running=false]:opacity-60 data-[running=false]:delay-75 data-[running=false]:duration-300 data-[running=false]:ease-in-out hover:data-[running=false]:opacity-100',
          ]"
          :data-running="index === activeIndex"
        >
          <Collapse v-slot="{ ref }" :open="activeIndex === index">
            <div
              class="transition-all duration-300 ease-out group-hover:group-data-[running=false]:pb-3 max-lg:group-hover:group-data-[running=false]:-mt-2 max-lg:group-hover:group-data-[running=false]:mb-2"
            >
              <!-- TITLE -->
              <h2 class="text-[var(--slide-content-color)] typo-heading-sm">
                {{ slide.entry.title }}
              </h2>
              <!-- COLLAPSE BUTTON -->
              <button
                :id="`collapseButton-${index}`"
                type="button"
                class="absolute inset-0 cursor-pointer hyphens-auto text-left"
                :aria-label="$t('teaser_slider_button_collapse')"
                aria-haspopup
                :aria-expanded="activeIndex === index"
                @focus="activeIndex = index"
                @click="activeIndex = index"
              />
            </div>
            <div
              :ref="ref"
              :aria-hidden="activeIndex !== index"
              :aria-labelledby="`collapseButton-${index}`"
            >
              <!-- TEXT -->
              <div
                v-if="slide.text"
                class="mt-6 max-w-prose text-[var(--slide-text-color)]"
                v-html="slide.text"
              />
              <!-- LINK -->
              <NuxtLink
                class="text-[var(--slide-content-color)] after:absolute after:inset-0"
                :to="slide.entry.url"
                :aria-label="slide.entry.title"
              >
                <IconArrowRight
                  class="mt-6 w-14 min-w-3 transition-transform group-hover:translate-x-[0.5em]"
                  stroke-width="1"
                />
              </NuxtLink>
            </div>
            <div
              class="border-[var(--slide-content-color)]/60 relative mt-6 border-t"
            >
              <div
                ref="progressEl"
                class="animate-progress absolute top-[-2px] block origin-left border-t-[3px] border-[var(--slide-content-color)]"
                @animationend="increaseActiveIndex()"
              />
            </div>
          </Collapse>
        </div>
      </Container>
    </div>
  </Container>
</template>

<script setup lang="ts">
import type { MainComponentsTeaserSliderFragment } from '#gql'

const props = defineProps<{
  data: Enforce<MainComponentsTeaserSliderFragment>
  index: number
}>()

const activeIndex = ref(0)
const progressEl = ref<HTMLElement | null>(null)

function gradientStyle(slide: any) {
  return (
    slide.entry.replicator_heros[0]?.gradient_style || slide.gradient_style
  ).value
}

function increaseActiveIndex() {
  activeIndex.value++
  if (activeIndex.value >= props.data.slides.length) {
    activeIndex.value = 0
  }
}
</script>

<style scoped lang="scss">
.playstate-handler:has([data-running='true']:hover) .animate-image-scaling {
  animation-play-state: paused;
}

.group:hover .animate-progress {
  animation-play-state: paused;
}

.group[data-running='true'] {
  .animate-progress {
    animation-name: progress;
    animation-timing-function: linear;
    animation-duration: 8s;
  }
}

.animate-image-scaling {
  animation-name: image-scaling;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes image-scaling {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1125);
  }
}
</style>
